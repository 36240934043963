// HomePage.js

import React from 'react';
import './services.css';

const ServicesPage = () => {
  return (
    <div className='services'>
      <div className='services-content'>
        <h1>Services</h1>
      </div>    
    </div>
  );
};

export default ServicesPage;
