// ContactPage.js

import React from 'react';
import './contact.css';
import { Translation } from 'react-i18next';
import DiscordLogo from './../../../assets/img/discord.svg';
import Logo512 from './../../../assets/img/logo512.png';

const ContactPage = () => {
  const discordInviteLink = "https://discord.gg/cp9JzUzJYK"; // Replace with your actual Discord invite link

  return (
    <Translation>
      {
        (t, { i18n }) => (
          <div className='contact'>
          <div className='contact-background'>
            <div className='contact-content'>
              <h1>Contact</h1>
              <p>{t('contactPage.bestWayToReach')}</p>
              <a href={discordInviteLink} target="_blank" rel="noopener noreferrer">
                <img src={DiscordLogo} alt="Discord Logo" className="discord-icon" />
              </a>
              <img src={Logo512} alt="Logo eiffel tower" className="eiffel-icon" />
            </div>
          </div> 
        </div>
        )
      }
    </Translation>
   
  );
};

export default ContactPage;
