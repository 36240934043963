// HomePage.js

import React from 'react';
import TitleImage from '../../../assets/img/frenchvibes.png';
import './home.css'; // Import the CSS file

const HomePage = () => {
  return (
      <div className="images">
        <img src={TitleImage} alt="French Vibes" />
      </div>
  );
};

export default HomePage;
