import React from 'react';
import { useTranslation } from 'react-i18next';
import './tos.css';

const TOS = () => {
  const { t } = useTranslation();

  return (
    <div className="tos-policy">
      <div className="tos-policy-container">
        <div className="tos-policy-content">

          <h1 className="tos-policy-title">{t('tos.title')}</h1>

          <div className="tos-intro">
            <h2>{t('tos.intro-title')}</h2>
            <p>{t('tos.intro')}</p>
          </div>

          <div className="user-agreement">
            <h2>{t('tos.userAgreement.title')}</h2>
            <p>{t('tos.userAgreement.paragraph1')}</p>
            <p>{t('tos.userAgreement.paragraph2')}</p>
          </div>

          <div className="user-conduct">
            <h2>{t('tos.userConduct.title')}</h2>
            <p>{t('tos.userConduct.paragraph1')}</p>
            <p>{t('tos.userConduct.paragraph2')}</p>
          </div>

          <div className="disclaimers">
            <h2>{t('tos.disclaimers.title')}</h2>
            <p>{t('tos.disclaimers.paragraph1')}</p>
            <p>{t('tos.disclaimers.paragraph2')}</p>
          </div>

          <div className="limitations-of-liability">
            <h2>{t('tos.limitationsOfLiability.title')}</h2>
            <p>{t('tos.limitationsOfLiability.paragraph1')}</p>
            <p>{t('tos.limitationsOfLiability.paragraph2')}</p>
          </div>

          <div className="changes">
            <h2>{t('tos.changes.title')}</h2>
            <p>{t('tos.changes.paragraph')}</p>
          </div>

          <div className="contact-us">
            <h2>{t('tos.contact.title')}</h2>
            <p>{t('tos.contact.paragraph')}</p>
          </div>
          <br/>

        </div>
      </div>
    </div>
  );
};

export default TOS;
