// App.js

import React, { useEffect, useState } from 'react';
import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CurrentDate from './app/components/date/date.jsx';
import Footer from './app/components/footer/footer.jsx';
import Navbar from './app/components/navbar/navbar.jsx';
import HomePage from './app/pages/home/home.jsx';
import AboutPage from './app/pages/about/about.jsx';
import ServicesPage from './app/pages/services/services.jsx';
import ContactPage from './app/pages/contact/contact.jsx';
import MobileNavbar from './app/components/mobilenavbar/mobilenavbar.js';
import PrivacyPolicy from './app/pages/privacyPolicy/privacyPolicy.jsx';
import CopyrightPolicy from './app/pages/copyright/copyrightPolicy.jsx';
import CookiesPolicy from './app/pages/cookiesPolicy/cookiesPolicy.jsx';
import TOS from './app/pages/tos/tos.jsx';

function App() {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [i18n.language]); // Include i18n.language as a dependency

  return (
    <Router>
      <div className="App background-container">
        <div className="navbar-section">
          {isMobile ? <MobileNavbar key={i18n.language} /> : <Navbar key={i18n.language} />}
        </div>
        <div className="content-section">
          <Routes>
            <Route path="/" element={<HomePage key={i18n.language} />} />
            <Route path="/about" element={<AboutPage key={i18n.language} />} />
            <Route path="/services" element={<ServicesPage key={i18n.language} />} />
            <Route path="/contact" element={<ContactPage key={i18n.language} />} />
            <Route path="/privacy" element={<PrivacyPolicy key={i18n.language} />} />
            <Route path="/copyright" element={<CopyrightPolicy key={i18n.language} />} />
            <Route path="/cookies" element={<CookiesPolicy key={i18n.language} />} />
            <Route path="/terms" element={<TOS key={i18n.language} />} />
          </Routes>
          <CurrentDate key={i18n.language} />
        </div>
        <div className="footer-section">
          <Footer key={i18n.language} />
        </div>
      </div>
    </Router>
  );
}

export default App;
