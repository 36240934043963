import React from 'react';
import { useTranslation } from 'react-i18next';
import './copyrightPolicy.css';

const CopyrightPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="copyright-policy">
      <div className="copyright-policy-container">
        <div className="copyright-policy-content">
          <h1 className="copyright-policy-title">{t('copyrightPolicy.title')}</h1>
          <p>{t('copyrightPolicy.intro')}</p>
          <p>{t('copyrightPolicy.paragraph1')}</p>
          <p>{t('copyrightPolicy.paragraph2')}</p>
          <p>{t('copyrightPolicy.paragraph3')}</p>
          <p>{t('copyrightPolicy.paragraph4')}</p>
          <br/>
        </div>
      </div>
    </div>
  );
};

export default CopyrightPolicy;
