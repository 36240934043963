// HomePage.js

import React from 'react';
import './about.css'

const AboutPage = () => {
  return (
  <div className='about'>
    <div className='about-content'>
      <h1>About</h1>
    </div>    
  </div>
  );
};

export default AboutPage;
