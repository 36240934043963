// mobilenavbar.jsx
import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './mobilenavbar.css';

const MobileNavbar = () => {
  const { t } = useTranslation();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const drawerRef = useRef(null);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  // Add event listener to close drawer when clicking outside
  useEffect(() => {
    const closeDrawer = (event) => {
      if (isDrawerOpen && drawerRef.current && !drawerRef.current.contains(event.target)) {
        setDrawerOpen(false);
      }
    };

    document.addEventListener('mousedown', closeDrawer);

    return () => {
      document.removeEventListener('mousedown', closeDrawer);
    };
  }, [isDrawerOpen]);

  return (
    <div className="mobilenavbar">
      <div className={`menu-icon${isDrawerOpen ? ' hidden' : ''}`} onClick={toggleDrawer}>
        &#9776;
      </div>
      <div ref={drawerRef} className={`drawer${isDrawerOpen ? ' open' : ''}`}>
        <NavLink to="/" onClick={toggleDrawer}>{t('home')}</NavLink>
        {/* <NavLink to="/about" onClick={toggleDrawer}>{t('about')}</NavLink>
        <NavLink to="/services" onClick={toggleDrawer}>{t('services')}</NavLink> */}
        <NavLink to="/contact" onClick={toggleDrawer}>{t('contact')}</NavLink>
      </div>
    </div>
  );
};

export default MobileNavbar;
