// date.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './date.css';

const CurrentDate = () => {
  const { i18n } = useTranslation();
  const [, setForceRender] = useState(); // Dummy state for triggering a re-render

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng).then(() => {
      // Trigger a re-render of the component by updating the dummy state
      setForceRender(Date.now());
    });
  };

  return (
    <div className="top-right-date">
      {/* Language switch buttons with conditional styling */}
      <button
        type="button"
        className={i18n.language === 'en' ? 'active' : ''}
        onClick={() => changeLanguage('en')}
      >
        EN
      </button>
      <button
        type="button"
        className={i18n.language === 'fr' ? 'active' : ''}
        onClick={() => changeLanguage('fr')}
      >
        FR
      </button>
    </div>
  );
};

export default CurrentDate;
