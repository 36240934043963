import React from 'react';
import { useTranslation } from 'react-i18next';
import './cookiesPolicy.css';

const CookiesPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="cookies-policy">
      <div className="cookies-policy-container">
        <div className="cookies-policy-content">
          <h1 className="cookies-policy-title">{t('cookiesPolicy.title')}</h1>

          <div className="cookies-introduction">
            <h2>{t('cookiesPolicy.intro-title')}</h2>
            <p>{t('cookiesPolicy.intro')}</p>
          </div>

          <div className="what-is-cookie">
            <h2>{t('cookiesPolicy.whatIsCookie.title')}</h2>
            <p>{t('cookiesPolicy.whatIsCookie.paragraph1')}</p>
          </div>

          <div className="what-website-does">
            <h2>{t('cookiesPolicy.whatWebsiteDoes.title')}</h2>
            <p>{t('cookiesPolicy.whatWebsiteDoes.paragraph1')}</p>
            <p>{t('cookiesPolicy.whatWebsiteDoes.paragraph2')}</p>
          </div>

          <div className="cookies-contact">
            <h2>{t('cookiesPolicy.cookiesContact.title')}</h2>
            <p>{t('cookiesPolicy.cookiesContact.paragraph3')}</p>
          </div>
          <br/>

        </div>
      </div>
    </div>
  );
};

export default CookiesPolicy;
