// Navbar.js

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './navbar.css';

const Navbar = () => {
  const { t } = useTranslation(); // Access i18n instance
  const location = useLocation();
  const linkStyle = { borderBottom: '4px solid white', paddingBottom: '3px' };

  return (
    <nav className="navbar">
      <ul>
        <li>
          <NavLink to="/" exact="true" style={location.pathname === '/' ? linkStyle : {}}>
            {t('home')}
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/about" style={location.pathname === '/about' ? linkStyle : {}}>
            {t('about')}
          </NavLink>
        </li>
        <li>
          <NavLink to="/services" style={location.pathname === '/services' ? linkStyle : {}}>
            {t('services')}
          </NavLink>
        </li> */}
        <li>
          <NavLink to="/contact" style={location.pathname === '/contact' ? linkStyle : {}}>
            {t('contact')}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
