// footer.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './footer.css';

const Footer = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const privacyTranslation = t('footer.privacy');

  return (
    <footer className="footer">
      <div className="footer-content">
        <div>
          <p>&copy; {t('footer.copyright', { year: new Date().getFullYear() })} </p>
          <p>
            <Link to="/privacy" onClick={scrollToTop}>{privacyTranslation}</Link> |{' '}
            <Link to="/terms" onClick={scrollToTop}>{t('footer.terms')}</Link> |{' '}
            <Link to="/cookies" onClick={scrollToTop}>{t('footer.cookies')}</Link> |{' '}
            <Link to="/copyright" onClick={scrollToTop}>{t('footer.copyrightPage')}</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
