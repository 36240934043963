import React from 'react';
import { useTranslation } from 'react-i18next';
import './privacyPolicy.css';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy">
      <div className="privacy-policy-container">
        <div className="privacy-policy-content">

          <h1 className="privacy-policy-title">{t('privacyPolicy.title')}</h1>

          <div className="privacyPolicy-introduction">
            <h2>{t('privacyPolicy.intro-title')}</h2>
            <p>{t('privacyPolicy.intro')}</p>
          </div>
        

          <div className="privacyPolicy-information-collection">
            <h2>{t('privacyPolicy.informationCollection.title')}</h2>
            <p>{t('privacyPolicy.informationCollection.paragraph1')}</p>
            <p>{t('privacyPolicy.informationCollection.paragraph2')}</p>
          </div>
              
          <div className="privacyPolicy-use-of-information">
            <h2>{t('privacyPolicy.useOfInformation.title')}</h2>
            <p>{t('privacyPolicy.useOfInformation.paragraph1')}</p>
            <p>{t('privacyPolicy.useOfInformation.paragraph15')}</p>
            <p>{t('privacyPolicy.useOfInformation.paragraph2')}</p>
          </div>
                
          <div className="privacyPolicy-security">
            <h2>{t('privacyPolicy.security.title')}</h2>
            <p>{t('privacyPolicy.security.paragraph')}</p>
          </div>
                  
          <div className="privacyPolicy-changes">
            <h2>{t('privacyPolicy.changes.title')}</h2>
            <p>{t('privacyPolicy.changes.paragraph')}</p>
          </div>
                  
          <div className="privacyPolicy-contact">
            <h2>{t('privacyPolicy.contact.title')}</h2>
            <p>{t('privacyPolicy.contact.paragraph')}</p>
          </div>
          <br/>
          
        </div>
      </div>
     
    </div>
  );
};

export default PrivacyPolicy;
